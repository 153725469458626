<template>
  <div class="publication-container bg-[#F3F3F3] lg:px-8 pt-16 p-6" v-if="listItem">
    <div class="lg:mb-6 mb-4">
      <h3 class="section-title mb-10 text-center font-family-85-ntd text-2xl">{{ $t('Ấn phẩm') }}</h3>
    </div>
    <div class="flex flex-wrap -mx-4">
      <div v-for="item in listItem" :key="item.id" class="lg:basis-1/2 p-4 max-md:w-full">
        <div
          class="bg-white flex max-sm:flex-col justify-start items-start gap-4 px-4 py-6 border border-bluePrimary lg:h-[227px] xl:h-full"
        >
          <div
            class="group publication_book relative overflow-hidden w-1/4 max-sm:w-full"
            :class="{ set_with_publication_book: isEven(listItem) }"
          >
            <img class="block w-full cursor-pointer" :src="item.img_url" alt="publications" />
            <div
              class="p-4 bg-[#606060] absolute left-0 -bottom-full max-sm:bottom-0 w-full group-hover:bottom-0 transition-all duration-500"
            >
              <div
                class="text-white mb-3 font-base lg:hidden font-semibold cursor-pointer font-family-85-ntd max-lg:hidden"
                @click="goToPublicationDetail(item?.id)"
              >
                {{ item?.publication_name }}
              </div>
              <div class="flex justify-start items-center text-white text-base font-bold">
                <span>{{ item?.file_size }}</span>
                <div class="ml-auto flex justify-start items-center gap-3">
                  <i
                    class="ri-download-fill cursor-pointer text-slate-200 hover:text-white"
                    @click.prevent="downloadFile(item?.file_path, item?.file_name)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-3/4 max-sm:w-full flex flex-col justify-center items-start h-full">
            <div class="overflow-hidden">
              <h4 class="text-xl mb-2 text-bluePrimary lg:font-semibold">
                <span
                  class="text-bluePrimary lg:overflow-hidden lg:max-h-[70px] lg:flex font-family-85-ntd cursor-pointer text-ellipsis-2"
                  @click="goToPublicationDetail(item?.id)"
                  >{{ item?.publication_name }}</span
                >
              </h4>
              <div
                class="text-base text-textBlackPrimary mb-4 lg:max-h-[76px] lg:overflow-hidden font-family-85-ntd lg:leading-[19px]"
              >
                <div class="mb-2">
                  <span class="cursor-pointer line-clamp-1"> Đơn vị thực hiện:{{ item?.agency_work }}</span>
                </div>
                <div>Năm công bố: {{ item?.release_year }}</div>
              </div>
            </div>
            <div class="mt-auto flex flex-wrap justify-start items-center">
              <span
                class="inline-block text-bluePrimary font-semibold hover:opacity-90 font-family-85-ntd cursor-pointer"
                @click="goToPublicationDetail(item?.id)"
                >{{ $t('Xem chi tiết') }}</span
              >
              <span class="text-bluePrimary font-semibold mx-4">|</span>
              <span
                class="inline-block text-bluePrimary font-semibold hover:opacity-90 font-family-85-ntd cursor-pointer"
                @click.prevent="downloadFile(item?.file_path, item?.file_name)"
                >{{ $t('Tải về') }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 text-center">
      <Link :href="route('publication-documents.index')" class="primary-button font-semibold px-10">{{
        $t('Xem tất cả')
      }}</Link>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import axios from '@/plugins/axios'
export default {
  name: 'Publication',
  data() {
    return {
      listItem: [],
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      const { status, data } = await axios.get('/get-publication-for-home-page')
      if (status === 200) {
        this.listItem = data?.data
      }
    },
    downloadFile(file, fileName) {
      const link = document.createElement('a')
      link.href = '/download-file?file=' + encodeURIComponent(file) + '&file_name=' + encodeURIComponent(fileName)
      link.target = '_blank'
      link.click()
    },
    previewFile(file) {
      window.open(file, '_blank')
    },
    goToPublicationDetail(publication_id) {
      this.$inertia.visit(route('publication-documents.detail', { id: publication_id }))
    },
    isEven(a) {
      if (a % 2 == 0) {
        return false
      }
      return true
    },
  },
}
</script>
<style scoped>
.font-family-85-ntd {
  font-family: '85 NTD Gilroy';
}
@media (min-width: 1024px) {
  .publication_book img {
    width: 137px !important;
    height: 179px !important;
    object-fit: cover;
  }
  .set_with_publication_book img {
    width: 100% !important;
  }
  .publication_book img ~ div {
    bottom: 0;
    width: 100% !important;
  }
}
.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
